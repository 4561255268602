import type {
  TenTouchTaskFieldsFragment,
  TaskFieldsFragment
} from "~/types/api";
import React from "react";
import { IconCompany } from "~/components/icons";
import Link from "~/components/link";

export const parentName = (
  task: TaskFieldsFragment | TenTouchTaskFieldsFragment,
  link = false,
  textOnly = false
) => {
  if (task.__typename === "TenTouchTask") {
    if (task.tenTouch) {
      const name = task.tenTouch.contact.informalDisplayAs;
      return link ? (
        <Link to={`/contacts/${task.tenTouch.contact.id}`}>{name}</Link>
      ) : (
        name
      );
    }
    return "N/A";
  } else if (task.contact) {
    const name = task.contact.informalDisplayAs;
    const item =
      task.contact.mode === "Company" && !textOnly ? (
        <>
          <IconCompany /> {name}
        </>
      ) : (
        name
      );
    return link ? (
      <Link to={`/contacts/${task.contact.id}`}>{item}</Link>
    ) : (
      item
    );
  } else if (task.project) {
    const name = `${task.project.number} ${task.project.name}`;
    return link ? (
      task.category === "A/R" ? (
        <Link to={`/projects/${task.project.number}/invoices`}>{name}</Link>
      ) : (
        <Link to={`/projects/${task.project.number}`}>{name}</Link>
      )
    ) : (
      name
    );
  } else if (task.interview) {
    const name = `${task.interview.project!.number} ${
      task.interview.project!.name
    }`;
    return link ? (
      <Link
        to={`/projects/${task.interview.project!.number}/meetings/${
          task.interview.id
        }/edit`}
      >
        {name}
      </Link>
    ) : (
      name
    );
  } else if (task.feature) {
    const name = `${task.feature.featureType}: ${task.feature.title}`;
    return link ? (
      <Link to={`/features/${task.feature.id}`}>{name}</Link>
    ) : (
      name
    );
  } else if (task.device) {
    const name = "Computer/Device";
    return link ? (
      <Link to={`/admin/devices/${task.device.id}`}>{name}</Link>
    ) : (
      name
    );
  } else if (task.employee) {
    return link ? (
      <Link to={`/employees/${task.employee.id}`}>
        {task.employee.displayAs}
      </Link>
    ) : (
      task.employee.displayAs
    );
  } else if (task.training) {
    return link ? (
      <Link to={`/training/${task.training.id}/notes`}>
        {task.training.name}
      </Link>
    ) : (
      task.training.name
    );
  } else if (task.candidate) {
    return link ? (
      <Link to={`/hiring/candidates/${task.candidate.id}`}>
        Candidate: {task.candidate.firstName} {task.candidate.lastName}
      </Link>
    ) : (
      `Candidate: ${task.candidate.firstName} ${task.candidate.lastName}`
    );
  }

  return "Personal Action";
};

export default {
  parentName
};
